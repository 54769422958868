<template>
  <v-container fluid class="dashboard-container">
    <v-row align="center" justify="center" class="mx-0">
      <v-col cols="12" class="px-2 py-2 synapsa-tabs">
        <v-btn
            v-for="(tab) in layout"
            :key="tab.id"
            :text="!tab.active"
            :color="tab.active ? 'primary' : ''"
            :outlined="tab.active"
            small
            class="text-capitalize synapsa-tab"
            :class="tab.active ? 'elevation-1' : ''"
            @click="changeTab(tab)"
        >
          {{ $t(tab.name) }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mx-0 my-0">
      <v-col cols="12" class="px-0 mb-10">
        <div
          v-for="(tab, tabKey) in layout"
          :key="tabKey"
        >
          <grid-layout
              v-if="tab.active"
              :id="`dashboard-widgets-block-test-${tabKey}`"
              :layout.sync="tab.widgets"
              :col-num="screenWidth >= 1200 ? 3 : 2"
              :row-height="35.5"
              :is-draggable="draggable"
              :is-resizable="resizable"
          >
            <grid-item
                v-for="(widget, widgetKey) in tab.widgets"
                :key="widgetKey"
                :static="widget.static"
                :x="widget.x"
                :y="widget.y"
                :w="widget.w"
                :h="widget.h"
                :i="widget.i"
                :style="getMarginByGrid(widget)"
                @resize="resizeEvent"
                @move="moveEvent"
                @container-resized="containerResizedEvent"
                @moved="movedEvent"
            >
              <component
                  :is="widget.component"
                  :i="widget.i"
                  :w="widget.w"
                  :h="widget.h"
                  :h-sizes="widget.hSizes"
                  :type="widget.type"
                  @resize="resizeWidget($event)"
                  @remove="removeWidget($event)"
              />
            </grid-item>
          </grid-layout>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VueGridLayout from "vue-grid-layout";
import { DashboardParser } from "@/views/dashboard/parser";
import defaultSettings from "@/store/widgets";

export default {
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  data() {
    return {
      layout: [],
      draggable: true,
      resizable: false,
    };
  },
  computed: {
    defaultDashboardTabs: {
      get() {
        return this.$store.state.widgets.defaultDashboardTabs;
      },
      set(val) {
        this.$store.dispatch("widgets/changeSetting", {
          key: "defaultDashboardTabs",
          value: val,
        });
      },
    },
    screenWidth: {
      get() {
        return this.$store.state.settings.screenWidth;
      },
      set(val) {
        this.$store.dispatch("settings/changeSetting", {
          key: "screenWidth",
          value: val,
        });
      },
    },
  },
  created() {
    const { defaultDashboardTabs } = defaultSettings;
    this.layout = defaultDashboardTabs;
  },
  methods: {
    moveEvent: function () {
      this.updateWidgets();
    },
    movedEvent: function () {
      this.updateWidgets();
    },
    resizeEvent: function () {
      this.updateWidgets();
    },
    containerResizedEvent: function () {
      this.updateWidgets();
    },
    resizeWidget(event) {
      const self = this;
      this.layout.forEach(function (tab) {
        tab.widgets.forEach(function (widget) {
          if (widget.i === event.i) {
            const oldH = widget.h;
            widget.h = event.h;
            self.layout.forEach(function (compareTab) {
              compareTab.widgets.forEach(function (compareWidget) {
                if (
                    compareWidget.x === widget.x &&
                    compareWidget.i !== widget.i &&
                    widget.y < compareWidget.y
                ) {
                  compareWidget.y += widget.h - oldH;
                }
              });
            });
          }
        })
      });
      this.updateWidgets();
    },
    removeWidget(event) {
      const self = this;
      this.layout.forEach(function (tab) {
        tab.widgets.forEach(function (widget, key) {
          if (event === widget.i) {
            self.layout.splice(key, 1);
          }
        })
      });
      this.updateWidgets();
    },
    getMarginByGrid(item) {
      return DashboardParser.getGridMargin(item);
    },
    updateWidgets() {
      this.defaultDashboardTabs = this.layout;
    },
    changeTab(tab) {
      this.layout.forEach(function (layoutTab) {
        layoutTab.active = false;
        if (layoutTab.id === tab.id) {
          layoutTab.active = true;
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.v-expansion-panels--popout > .v-expansion-panel {
  max-width: 100%;
  border-radius: 0 0 15px 15px;
}
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active {
  border-radius: 0 0 15px 15px;
}
.cogs-icon {
  vertical-align: sub;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: #1e1e1e96 !important;
  color: #fff !important;
  .v-icon {
    color: #fff !important;
  }
}
.theme--light.v-expansion-panels .v-expansion-panel {
  background-color: #ffffff6e !important;
  color: #3d3d3f !important;
  .v-icon {
    color: #3d3d3f !important;
  }
}
.v-expansion-panel-header__icon {
  .v-icon {
    color: #000000;
  }
}
.vue-grid-layout {
  background: transparent;
  margin-top: -5px;
  margin-left: -10px;
  margin-right: -10px;
}
.vue-grid-item:not(.vue-grid-placeholder) {
  background: transparent;
  border-radius: 15px;
}
.vue-grid-item .resizing {
  opacity: 0.9;
}
.vue-grid-item .static {
  background: transparent;
}
.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}
.vue-grid-item .minMax {
  font-size: 12px;
}
.vue-grid-item .add {
  cursor: pointer;
}
.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  padding: 0 8px 8px 0;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}
</style>

<style lang="scss">
.dashboard-container {
  .vue-grid-item.vue-grid-placeholder {
    background: #1e1e1e96 !important;
  }
  .v-card.theme--light {
    box-shadow: 13px 13px 30px rgb(0 0 0 / 10%);
    background-color: #fff;
    .v-card__title {
      background-color: #e2e3e5 !important;
      color: #3d3d3f !important;
    }
    .with-bg {
      background: #fff;
    }
  }
  .v-card.theme--dark {
    box-shadow: 13px 13px 30px rgb(0 0 0 / 30%);
    background-color: #4b4c4d;
    .v-card__title {
      background-color: #1f221dba !important;
      color: #fff !important;
    }
    .with-bg {
      background: #4b4c4d;
    }
  }
  .v-card {
    // height: 100%;
    border-radius: 15px;

    .v-card__title {
      font-family: UbuntuBold, sans-serif;
      font-size: 15px;
    }
    .v-card__text {
      border-radius: 0;
    }
  }
  .v-progress-linear {
    border-radius: 15px;
    .v-progress-linear__content {
      color: #fff;
    }
  }
  .widget-headline {
    font-size: 12px;
  }
  .widget-action-btn {
    .v-icon {
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 1263px) {
    .widget-content {
      .v-card__text {
        font-size: 12px;
      }
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1263px) {
    .widget-headline {
      font-size: 11px;
    }
  }
  .v-card.theme--light {
    .v-overlay.v-overlay--active {
      .v-overlay__scrim {
        opacity: 1 !important;
        background-color: #ffffff6e !important;
        border-color: #ffffff6e !important;
      }
    }
  }
}
</style>
