export const DashboardParser = {
  getGridMargin: function (item) {
    let style = "touch-action: none; ";
    if ([0, 1].includes(item.x)) {
      const block = document.getElementById("dashboard-widgets-block");
      if (block && block.getBoundingClientRect()) {
        const blockW = block.getBoundingClientRect().width;
        let widgetW = (item.w * blockW - (40 / item.w)) / 3 - 10;
        style += "width: " + widgetW + "px; ";
      }
      style += "margin-right: 10px; ";
    }
    return style;
  },
};
